import yup from 'utils/yup';
import { TFunction } from 'i18next';
import { EntityType, Salutation } from '@types';
import {
  COMPANY_NAME_REGEX,
  CUSTOM_LATEN_REGEX,
  PHONE_NUMBER_REGEX,
  STREET_NO_REGEX,
  VAT_NUMBER_REGEX,
  ZIP_REGEX,
} from 'utils/constants/regex';
import { isPostalCode, PostalCodeLocale } from 'validator';

export interface AboutFormValues {
  companyName?: string;
  entityType?: EntityType;
  salutation?: string;
  surname?: string;
  name?: string;
  dateOfBirth?: string;

  street?: string;
  streetNo?: string;
  zip?: string;
  city?: string;
  area?: string;
  country?: string;

  email?: string;
  tel?: string;
  vatNo?: string;
}

export const aboutFormDefaults: AboutFormValues = {};
export const aboutFormSchema = (t: TFunction) =>
  yup.object({
    companyName: yup
      .string()
      .required(t('form:error.required'))
      .min(2, t('form:error.invalid.companyName'))
      .matches(COMPANY_NAME_REGEX, t('form:error.invalid.default')),
    name: yup
      .string()
      .required(t('form:error.required'))
      .matches(CUSTOM_LATEN_REGEX, t('form:error.invalid.nonLatin')),
    surname: yup
      .string()
      .required(t('form:error.required'))
      .matches(CUSTOM_LATEN_REGEX, t('form:error.invalid.nonLatin')),
    entityType: yup
      .string()
      .oneOf(Object.values(EntityType))
      .required(t('form:error.required')),
    dateOfBirth: yup
      .string()
      .typeError(t('form:error.invalid.default'))
      .when('entityType', (entityType, schema) => {
        return entityType === EntityType.Natural
          ? schema.required(t('form:error.required'))
          : schema;
      }),
    salutation: yup
      .string()
      .oneOf(Object.values(Salutation))
      .required(t('form:error.required')),
    street: yup
      .string()
      .matches(CUSTOM_LATEN_REGEX, t('form:error.invalid.nonLatin'))
      .required(t('form:error.required')),
    streetNo: yup
      .string()
      .matches(STREET_NO_REGEX, t('form:error.invalid.alphanumeric'))
      .required(t('form:error.required')),
    zip: yup
      .string()
      .required(t('form:error.required'))
      .when('country', (country: string, schema) => {
        return country
          ? schema.test('zip', t('form:error.invalid.zip'), (value: string) => {
              if (!value) {
                return true;
              }
              try {
                return isPostalCode(value, country as PostalCodeLocale);
              } catch (e) {
                // validator doesn't support the country, fallback to regex
                return new RegExp(ZIP_REGEX).test(value);
              }
            })
          : schema;
      }),
    city: yup
      .string()
      .matches(CUSTOM_LATEN_REGEX, t('form:error.invalid.nonLatin'))
      .required(t('form:error.required')),
    area: yup
      .string()
      .matches(CUSTOM_LATEN_REGEX, t('form:error.invalid.nonLatin'))
      .required(t('form:error.required')),
    country: yup.string().required(t('form:error.required')),
    vatNo: yup
      .string()
      .uppercase()
      .matches(VAT_NUMBER_REGEX, t('form:error.invalid.vatNo'))
      .required(t('form:error.required'))
      .when('country', (country: string, schema) => {
        const regex = new RegExp(`^${country}`);
        return country
          ? schema.matches(regex, t('form:error.invalid.vatCountry'))
          : schema;
      }),
    email: yup
      .string()
      .email(t('form:error.invalid.email'))
      .required(t('form:error.required')),
    tel: yup
      .string()
      .trim()
      .matches(PHONE_NUMBER_REGEX, t('form:error.invalid.phone'))
      .required(t('form:error.required')),
  });
